import {createContext, useContext, useEffect, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import {useAuth} from "../auth/AuthProvider";
import {useAppContext} from "../AppContext"
import {getFakeMachine} from "../../lib/api/getFakeMachine";
import {reject_codes} from "../../lib/api/api_errors";
import {useAuthenticator} from "@aws-amplify/ui-react";

const MyCognitoAuthContext = createContext(null);

export const CognitoAuthContext = ({children}) => {
    // cognito stuff
    const [jwt, setJwt] = useState(0);
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const {fakeMachine, setFakeMachine} = useAppContext();


    const {token} = useAuth();


    useEffect( () => {
        Auth.currentSession().then(res=> {
            if (token && user ) {
                getFakeMachine(token, user.username).then(data => {
                    // console.log(">>> getFakeMachine returned >>>>>");
                    // console.log(data);
                    // console.log("<<<");
                    setFakeMachine(data);
                }).catch(reject_code => {
                    if (reject_code !== reject_codes.ITEM_NOT_FOUND) {
                        console.log("Error when getting fake machine info")
                    }
                })
            }

            let idToken = res.getIdToken();
            setJwt(idToken.getJwtToken());
        });

    }, [token, user]);

    const value = {
        cognitoUser: user,
        jwt: jwt,
        signOut: signOut
    }

    return(
        <MyCognitoAuthContext.Provider value={value}>
            {children}
        </MyCognitoAuthContext.Provider>
    )

};


export const useCognitoAuthContext = () => {
    return useContext(MyCognitoAuthContext);
}
