import {Button} from "react-bootstrap";

export const CertificatesDownloader = ({certificatePEM, privateKeyPEM, rootCACertificatePEM}) => {

    const downloadCertificatePEM = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        //link.download = `${uniqueId}-certificate.pem`;
        link.download = `certificate.pem`;
        link.href = `data:plain/text;base64,${certificatePEM}`;
        link.click();
    };

    const downloadPrivateKeyPEM = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        //link.download = `${uniqueId}-private.key.pem`;
        link.download = `private.key.pem`;
        link.href = `data:plain/text;base64,${privateKeyPEM}`;
        link.click();
    };

    const downloadRootCACertificatePEM = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        // link.download = `${uniqueId}-root-ca.pem`;
        link.download = `root-ca.pem`;
        link.href = `data:plain/text;base64,${rootCACertificatePEM}`;
        link.click();
    };

    return (
        <> { certificatePEM && privateKeyPEM && rootCACertificatePEM &&
            <>
                <p>
                    <Button onClick={downloadCertificatePEM}>Download Certificate PEM</Button>
                </p>
                <p>
                    <Button onClick={downloadPrivateKeyPEM}>Download Private Key PEM</Button>
                </p>
                <p>
                    <Button onClick={downloadRootCACertificatePEM}>Download Root CA Certificate PEM</Button>
                </p>
            </>

        }
        </>

    )
}
