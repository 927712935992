import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import {CopyToClipboard} from "react-copy-to-clipboard";

function CopyToClipButton(props) {
    const [isCopied, setCopied] = useState(false);

    useEffect(() => {
        function simulateNetworkRequest() {
            return new Promise((resolve) => setTimeout(resolve, 1500));
        }

        if (isCopied) {
            simulateNetworkRequest().then(() => {
                setCopied(false);
            });
        }
    }, [isCopied]);

    const handleClick = () => setCopied(true);

    return (
            <CopyToClipboard text={props.text}>
            <Button
                variant="primary"
                disabled={isCopied}
                onClick={!isCopied ? handleClick : null}
            >
                {isCopied ? 'Copied!' : props.children}
            </Button>
            </CopyToClipboard>

    );
}

export default CopyToClipButton;
