import {Container} from "react-bootstrap";

const Home = () => {

    return (
        <Container>
            <h1>Drinklang Backend dashboard</h1>
        </Container>
    );
};

export default Home;
