import sendLocalRequest from "./sendLocalRequest";

export const loginApi = (username, password, deviceId) => {
    const data = {
        "username": username,
        "password": password,
        "device_id": deviceId,
    }

    return new Promise( (resolve, reject) => {
        sendLocalRequest("login", "POST", null,  data).then( (data) => {
            resolve([data["token"], data["refresh_token"], data["env"], data["api_endpoint"]]);
        }).catch( (error) => {
            reject(error);
        })
    });
}
