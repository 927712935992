import {useAppContext} from "../components/AppContext";
import {Button, Card,Row, Col, Container, Form} from "react-bootstrap";
import {MachineParameter} from "../components/pages/operate_machine/MachineParameter";
import {BooleanMachineParameter} from "../components/pages/operate_machine/BooleanMachineParameter";
import {callMqttUpdateStatus} from "../lib/api/callMqttUpdateStatus";
import {useState} from "react";
import {useAuth} from "../components/auth/AuthProvider";
import {MobileApplicationUpdatesReceiver} from "../components/pages/operate_machine/MobileApplicationUpdatesReceiver";
import {SpaceSeparator} from "../components/ui/SpaceSeparator";
import {CognitoAuthContext} from "../components/cognito/CognitoAuthContext";
import { Authenticator } from '@aws-amplify/ui-react';
import { getCurrentTsString} from "../helpers/time/getCurrentTsString"
import {LoadingSpinner} from "../components/ui/LoadingSpinner";

export const OperateMachine = () => {
    const {fakeMachine} = useAppContext();

    // Message variables
    const [ackToken, setAckToken] = useState("AAABBB");
    const [fwVer, setFwVer] = useState("10.0.0");
    const [mediaVer, setMediaVer] = useState("10.0.0");
    const [checkForUpdates, setCheckForUpdates] = useState(true);
    const [lastRequestTS, setLastRequestTS] = useState();
    const [isSendingStatus, setIsSendingStatus] = useState(false);

    // counters read only
    const [dmk_0001, setDmk_0001] = useState(0);        // total processed liters
    const [dmk_0002, setDmk_0002] = useState(0);        // total processed liters current filters
    const [dmk_0003, setDmk_0003] = useState(0);        // total processed hot water liters
    const [dmk_0004, setDmk_0004] = useState(0);        // total dispensed drops a
    const [dmk_0005, setDmk_0005] = useState(0);        // total dispensed drops b
    const [dmk_0006, setDmk_0006] = useState(0);        // total dispensed drops c
    const [dmk_0007, setDmk_0007] = useState(0);        // total boiler time
    const [dmk_0008, setDmk_0008] = useState(0);        // total uv lamp time

    // maintenance read only
    const [dmk_0101, setDmk_0101] = useState(0);        // change filters timestamp
    const [dmk_0102, setDmk_0102] = useState(0);        // hot cleaning timestam
    const [dmk_0103, setDmk_0103] = useState(0);        // flush timestamp
    const [dmk_0104, setDmk_0104] = useState(0);        // average flow rate

    // errors read only
    const [dmk_0201, setDmk_0201] = useState(0);        // error mask 1

    // status read only
    const [dmk_0301, setDmk_0301] = useState(0);        // flow rate
    const [dmk_0302, setDmk_0302] = useState(0);        // processed water in serving
    const [dmk_0303, setDmk_0303] = useState(0);        // full level in serving
    const [dmk_0304, setDmk_0304] = useState(0);        // remaining level in serving
    const [dmk_0305, setDmk_0305] = useState(0);        // input water temperature
    const [dmk_0306, setDmk_0306] = useState(0);        // boiler temperature
    const [dmk_0307, setDmk_0307] = useState(0);        // input tds
    const [dmk_0308, setDmk_0308] = useState(0);        // output tds
    const [dmk_0309, setDmk_0309] = useState(0);        // filter rejection rate
    const [dmk_030A, setDmk_030A] = useState(0);        // is change filters in progress
    const [dmk_030B, setDmk_030B] = useState(0);        // spout state
    const [dmk_030C, setDmk_030C] = useState(0);        // tap water jar state
    const [dmk_030D, setDmk_030D] = useState(0);        // uv lamp state
    const [dmk_030E, setDmk_030E] = useState(0);        // processing type

    // containers read only
    const [dmk_0401, setDmk_0401] = useState(0);        // container a status
    const [dmk_0402, setDmk_0402] = useState(0);        // container a id
    const [dmk_0403, setDmk_0403] = useState(0);        // container a percent left
    const [dmk_0404, setDmk_0404] = useState(0);        // container b status
    const [dmk_0405, setDmk_0405] = useState(0);        // container b id
    const [dmk_0406, setDmk_0406] = useState(0);        // container b percent left
    const [dmk_0407, setDmk_0407] = useState(0);        // container c status
    const [dmk_0408, setDmk_0408] = useState(0);        // container c id
    const [dmk_0409, setDmk_0409] = useState(0);        // container c percent left
    const [dmk_040A, setDmk_040A] = useState(0);        // container Tag UID 1
    const [dmk_040B, setDmk_040B] = useState(0);        // container Tag UID 2
    const [dmk_040C, setDmk_040C] = useState(0);        // container Tag UID 3

    // settings read-write
    const [dmk_0501, setDmk_0501] = useState(0);        // timestamp
    const [dmk_0502, setDmk_0502] = useState(0);        // fill level 0
    const [dmk_0503, setDmk_0503] = useState(0);        // fill level 1
    const [dmk_0504, setDmk_0504] = useState(0);        // fill level 2
    const [dmk_0505, setDmk_0505] = useState(0);        // hot water temperature
    const [dmk_0506, setDmk_0506] = useState(0);        // flavor temperature
    const [dmk_0507, setDmk_0507] = useState(0);        // language
    const [dmk_0508, setDmk_0508] = useState(0);        // high tds warning enabled
    const [dmk_0509, setDmk_0509] = useState(0);        // flavor intensity
    const [dmk_050A, setDmk_050A] = useState(0);        // mineral intensity
    const [dmk_050B, setDmk_050B] = useState(0);        // hot cleaning interval
    const [dmk_050C, setDmk_050C] = useState(0);        // child mode enabled

    // firmware and media version - read only
    const [dmk_0A00, setDmk_0A00] = useState(0);        // firmware version major
    const [dmk_0A01, setDmk_0A01] = useState(0);        // firmware version middle
    const [dmk_0A02, setDmk_0A02] = useState(0);        // firmware version minor
    const [dmk_0A03, setDmk_0A03] = useState(0);        // media version major
    const [dmk_0A04, setDmk_0A04] = useState(0);        // media version middle
    const [dmk_0A05, setDmk_0A05] = useState(0);        // media version minor
    const [dmk_0A06, setDmk_0A06] = useState(0);        // data model version

    // don't update, current timestamp
    const [dmk_0B00, setDmk_0B00] = useState(0);        // media version minor

    const {token} = useAuth();

    const counter_vars = {
        "dmk_0001": [dmk_0001, setDmk_0001, "Tot. precessed liters"],
        "dmk_0002": [dmk_0002, setDmk_0002, "Tot. precessed liters cur. fitlers"],
        "dmk_0003": [dmk_0003, setDmk_0003, "Tot. precessed hot water lts"],
        "dmk_0004": [dmk_0004, setDmk_0004, "Tot. drops a"],
        "dmk_0005": [dmk_0005, setDmk_0005, "Tot. drops b"],
        "dmk_0006": [dmk_0006, setDmk_0006, "Tot. drops c"],
        "dmk_0007": [dmk_0007, setDmk_0007, "Tot. boiler time"],
        "dmk_0008": [dmk_0008, setDmk_0008, "Tot. uv time"]
    }

    const maintenance_vars = {
        "dmk_0101": [dmk_0101, setDmk_0101, "Change filters ts"],
        "dmk_0102": [dmk_0102, setDmk_0102, "Hot cleaning ts"],
        "dmk_0103": [dmk_0103, setDmk_0103, "Flush ts"],
        "dmk_0104": [dmk_0104, setDmk_0104, "Avg flow rate"]
    }

    const errors_vars = {
        "dmk_0201": [dmk_0201, setDmk_0201, "Error mask 1"],
    }

    const status_vars = {
        "dmk_0301": [dmk_0301, setDmk_0301, "Flow rate"],
        "dmk_0302": [dmk_0302, setDmk_0302, "Processed water in srv."],
        "dmk_0303": [dmk_0303, setDmk_0303, "Full level in srv."],
        "dmk_0304": [dmk_0304, setDmk_0304, "Remaining level in srv."],
        "dmk_0305": [dmk_0305, setDmk_0305, "Input water temp"],
        "dmk_0306": [dmk_0306, setDmk_0306, "Boiler temp"],
        "dmk_0307": [dmk_0307, setDmk_0307, "Input tds"],
        "dmk_0308": [dmk_0308, setDmk_0308, "Output tds"],
        "dmk_0309": [dmk_0309, setDmk_0309, "Filter reject rate"],
        "dmk_030A": [dmk_030A, setDmk_030A, "Is change filters in prog"],
        "dmk_030B": [dmk_030B, setDmk_030B, "Spout state"],
        "dmk_030C": [dmk_030C, setDmk_030C, "Tap water jar state"],
        "dmk_030D": [dmk_030D, setDmk_030D, "Uv lamp state"],
        "dmk_030E": [dmk_030E, setDmk_030E, "Processing type"]
    }

    const containers_vars = {
        "dmk_0401": [dmk_0401, setDmk_0401, "Container A status"],
        "dmk_0402": [dmk_0402, setDmk_0402, "Container A id"],
        "dmk_0403": [dmk_0403, setDmk_0403, "Container A percent left"],
        "dmk_0404": [dmk_0404, setDmk_0404, "Container B status"],
        "dmk_0405": [dmk_0405, setDmk_0405, "Container B id"],
        "dmk_0406": [dmk_0406, setDmk_0406, "Container B percent left"],
        "dmk_0407": [dmk_0407, setDmk_0407, "Container C status"],
        "dmk_0408": [dmk_0408, setDmk_0408, "Container C id"],
        "dmk_0409": [dmk_0409, setDmk_0409, "Container C percent left"],
        "dmk_040A": [dmk_040A, setDmk_040A, "Container Tag UID 1"],
        "dmk_040B": [dmk_040B, setDmk_040B, "Container Tag UID 2"],
        "dmk_040C": [dmk_040C, setDmk_040C, "Container Tag UID 3"],
    }

    const settings_vars = {
        "dmk_0501": [dmk_0501, setDmk_0501, "Timestamp"],
        "dmk_0502": [dmk_0502, setDmk_0502, "Fill level 0"],
        "dmk_0503": [dmk_0503, setDmk_0503, "Fill level 1"],
        "dmk_0504": [dmk_0504, setDmk_0504, "Fill level 2"],
        "dmk_0505": [dmk_0505, setDmk_0505, "Hot water temp"],
        "dmk_0506": [dmk_0506, setDmk_0506, "Flavor temp"],
        "dmk_0507": [dmk_0507, setDmk_0507, "Language"],
        "dmk_0508": [dmk_0508, setDmk_0508, "High tds warning enabled"],
        "dmk_0509": [dmk_0509, setDmk_0509, "Flavor intensity"],
        "dmk_050A": [dmk_050A, setDmk_050A, "Mineral intensity"],
        "dmk_050B": [dmk_050B, setDmk_050B, "Hot cleaning interval"],
        "dmk_050C": [dmk_050C, setDmk_050C, "Child mode enabled"]
    }

    const firmware_vars = {
        "dmk_0A00": [dmk_0A00, setDmk_0A00, "Firmware ver. maj"],
        "dmk_0A01": [dmk_0A01, setDmk_0A01, "Firmware ver. mid"],
        "dmk_0A02": [dmk_0A02, setDmk_0A02, "Firmware ver. min"],
        "dmk_0A03": [dmk_0A03, setDmk_0A03, "Media ver. maj"],
        "dmk_0A04": [dmk_0A04, setDmk_0A04, "Media ver. mid"],
        "dmk_0A05": [dmk_0A05, setDmk_0A05, "Media ver. min"],
        "dmk_0A06": [dmk_0A06, setDmk_0A06, "Data model version"]
    }

    const other_vars = {
        "dmk_0B00": [dmk_0B00, setDmk_0B00, "Current timestamp"]
    }


    const _forceIntOrBool = (v) => {
        let vi = parseInt(v);
        if (isNaN(vi)) {
            console.log("v is: " + v);

            // check if is boolean
            try {
                if (v === true || v === false) {
                    return v;
                }

                if(v.toLowerCase() === "true" || v.toLowerCase() === "false") {
                    if (v.toLowerCase() === "true") {
                        return true;
                    }
                    else if (v.toLowerCase() === "false") {
                        return false;
                    }
                }
            } catch (e) {
                console.log(`Error parsing as integer value ${v}, forced at 0`);
                console.log(e);
                return 0;
            }

            console.log(`Error parsing as integer value ${v}, forced at 0`);
            return 0;
        }
        return vi;
    }

    const _buildDatamodelDictForVars = (vars) => {
        var dct = {}

        Object.entries(vars).forEach(([key, value]) => {
            const k = key.replace("dmk_", "");
            dct[k] = _forceIntOrBool(value[0]);
        });
        return dct;
    }

    const _buildUpdatePayload = () => {
        const dataModel = Object.assign({},
            _buildDatamodelDictForVars(counter_vars),
            _buildDatamodelDictForVars(maintenance_vars),
            _buildDatamodelDictForVars(errors_vars),
            _buildDatamodelDictForVars(status_vars),
            _buildDatamodelDictForVars(containers_vars),
            _buildDatamodelDictForVars(settings_vars),
            _buildDatamodelDictForVars(firmware_vars),
            _buildDatamodelDictForVars(other_vars),
        );

        const payload = {
            "data_model": dataModel,
            "ack_token": ackToken,
            "fw_ver": fwVer,
            "media_ver": mediaVer,
            "check_for_updates": _forceIntOrBool(checkForUpdates)
        }
        return payload;
    }




    const sendData = () => {
        setIsSendingStatus(true);
        const payload = _buildUpdatePayload();
        console.log("> Sending update ")
        console.log(payload);
        console.log("<")
        callMqttUpdateStatus(token, fakeMachine.machineUniqueID, payload).then( data => {
            setLastRequestTS(getCurrentTsString());
            setIsSendingStatus(false);
            console.log("> call sent");
            console.log(data);

        }).catch( error => {
            console.log(">>>>> error when sending update data via mqtt");
            console.log(error);
        })
    }


    return (
        <>


            <h1>Operate machine</h1>
            { fakeMachine &&
                <Authenticator>
                <CognitoAuthContext>

                    <div>Machine: {fakeMachine.machineUniqueID} &nbsp; &nbsp;
                        <LoadingSpinner loadingFlag={isSendingStatus}>
                            <Button onClick={sendData}>Send data</Button>
                        </LoadingSpinner>
                        &nbsp;
                        { lastRequestTS &&
                            <span className="status-update">Last request TS: {lastRequestTS}</span>
                        }
                    </div>

                    <SpaceSeparator height={22}/>
                    <MobileApplicationUpdatesReceiver/>
                    <SpaceSeparator height={22}/>

                    <Row>
                        <Col>
                            <Card>
                                <Container>
                                    <Card.Title>Message variables</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <MachineParameter
                                                    label={"ACK Token"}
                                                    name={"ackToken"}
                                                    variable={ackToken}
                                                    setter={setAckToken}
                                                />

                                                <MachineParameter
                                                    label={"Firmware Version"}
                                                    name={"fwVer"}
                                                    variable={fwVer}
                                                    setter={setFwVer}
                                                />
                                                <MachineParameter
                                                    label={"Media Version"}
                                                    name={"mediaVer"}
                                                    variable={mediaVer}
                                                    setter={setMediaVer}
                                                />

                                                <BooleanMachineParameter
                                                    label={"Check for updates"}
                                                    name={"checkForUpdates"}
                                                    variable={checkForUpdates}
                                                    setter={setCheckForUpdates}
                                                />

                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>




                            <Card>
                                <Container>
                                    <Card.Title>Counters</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(counter_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={counter_vars[key][2]}
                                                            name={key}
                                                            variable={counter_vars[key][0]}
                                                            setter={counter_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>

                            <Card>
                                <Container>
                                    <Card.Title>Maintenance</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(maintenance_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={maintenance_vars[key][2]}
                                                            name={key}
                                                            variable={maintenance_vars[key][0]}
                                                            setter={maintenance_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>

                            <Card>
                                <Container>
                                    <Card.Title>Status</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(status_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={status_vars[key][2]}
                                                            name={key}
                                                            variable={status_vars[key][0]}
                                                            setter={status_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>

                            <Card>
                                <Container>
                                    <Card.Title>Containers</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(containers_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={containers_vars[key][2]}
                                                            name={key}
                                                            variable={containers_vars[key][0]}
                                                            setter={containers_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>

                            <Card>
                                <Container>
                                    <Card.Title>Settings</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(settings_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={settings_vars[key][2]}
                                                            name={key}
                                                            variable={settings_vars[key][0]}
                                                            setter={settings_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>

                            <Card>
                                <Container>
                                    <Card.Title>Firmware</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(firmware_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={firmware_vars[key][2]}
                                                            name={key}
                                                            variable={firmware_vars[key][0]}
                                                            setter={firmware_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>

                            <Card>
                                <Container>
                                    <Card.Title>Other</Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                {
                                                    Object.keys(other_vars).map((key, index) => (
                                                        <MachineParameter
                                                            label={other_vars[key][2]}
                                                            name={key}
                                                            variable={other_vars[key][0]}
                                                            setter={other_vars[key][1]}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Container>
                            </Card>


                        </Col>

                    </Row>

                </CognitoAuthContext>
                </Authenticator>
            }

        </>


    )
}
