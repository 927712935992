import sendLocalRequest from "./sendLocalRequest";

export const saveFakeMachine = (auth_token,
                                cognito_username,
                                cognito_email,
                                machine_unique_id,
                                machine_sn,
                                certificate_pem,
                                certificate_pk,
                                ca_root_cert
                                ) => {

    const data = {
        "cognitoUsername":  cognito_username,
        "cognitoEmail":  cognito_email,
        "machineUniqueID": machine_unique_id,
        "machineSerialNumber": machine_sn,
        "mqttCert": {
            "certificatePEM": certificate_pem,
            "privateKeyPEM": certificate_pk,
            "rootCACertificatePEM": ca_root_cert
        }
    }

    return  new Promise( (resolve, reject) => {
        sendLocalRequest(`fake_machine`, "POST", auth_token, data).then( (data) => {
            resolve(data);
        }).catch( (error) => {
            reject(error);
        })
    });
};
