import {Col, Form} from "react-bootstrap";

export const BooleanMachineParameter = ({label, name, variable, setter} ) => {
    return(
        <>
            <Col className={"col-md-2"}>
                <Form.Label for={name}>{label}</Form.Label>
            </Col>
            <Col className={"col-md-1"}>
                <Form.Select value={variable} name={name} onChange={e => setter(e.target.value)}>
                    <option>-</option>
                    <option value={false}>False</option>
                    <option value={true}>True</option>
                </Form.Select>
            </Col>
        </>
    )
};
