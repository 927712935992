import {Card, Button, Col, Row, Container,} from "react-bootstrap";
import CopyToClipButton from "../ui/CopyToClipButton";
import {useCognitoAuthContext} from "./CognitoAuthContext";
import {ping_auth} from "../../lib/backend/ping";
import {useState} from "react";

export function CognitoUserInformation() {

    const {cognitoUser, jwt, signOut} = useCognitoAuthContext();
    const [result, setResult] = useState("");

    const pingAuth = () => {
        setResult("")
        ping_auth().then( (data) => {
            setResult(JSON.stringify(data));
        }).catch((error) => {
            console.log(error);
            alert("Error ... see console!");
        })
    }

    return(
        <Card >
            <Card.Body>
                <Card.Title>Logged user: {cognitoUser.username}</Card.Title>
                <Container>
                <Row>
                    <Col>
                        <div>
                            <p>{cognitoUser.attributes.email}</p>
                            <CopyToClipButton text={jwt}>Copy Cognito token to clipboard</CopyToClipButton> {' '}
                            <Button onClick={pingAuth}>Ping Auth</Button> {' '}
                            <Button onClick={signOut}>Sign Out</Button> {' '}
                        </div>
                    </Col>

                    { result && <Col>
                        <textarea className="form-control" readOnly>{result}</textarea>
                    </Col> }
                </Row>
                </Container>

            </Card.Body>
        </Card>
    )
}
