import {Spinner} from "react-bootstrap";

export const LoadingSpinner = ({loadingFlag, children}) => {

    return (
        <>
        { loadingFlag ? <Spinner animation="border" role="status"/> :  children }
        </>
    )
}
