import {Col, Form} from "react-bootstrap";

export const MachineParameter = ({label, name, variable, setter} ) => {
    return(
        <>
            <Col className={"col-md-2"}>
                <Form.Label for={name}>{label}</Form.Label>
            </Col>
            <Col className={"col-md-1"}>
                <Form.Control
                    name={name} type="text"
                    value={variable}
                    onChange={e => setter(e.target.value)}
                />
            </Col>
        </>
    )
};
