import "../style.css"
import {useEffect, useRef, useState} from "react";

import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import {CognitoUserInformation} from "../components/cognito/CognitoUserInformation";
import {useAppContext} from "../components/AppContext";
import {StartProvisioningCaller} from "../components/pages/provision/StartProvisioningCaller";
import {CompleteProvisioningCaller} from "../components/pages/provision/CompleteProvisioningCaller";
import {CognitoAuthContext} from "../components/cognito/CognitoAuthContext";
import {IoTRequestProvisioning} from "../components/pages/provision/IoTRequestProvisioning";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {FakeMachineInfo} from "../components/pages/provision/FakeMachineInfo";
import AppConfiguration from "../components/AppConfiguration";

import {awsConfiguration_dev, awsConfiguration_prod} from "../aws-exports-all";

const env = AppConfiguration.getEnv();

console.log("Starting amplify environment: " + env)
if (process.env.REACT_APP_ENV === "dev") {
    console.log("Configuring for DEV environment");
    await Amplify.configure(awsConfiguration_dev);
}
else if (env === "prod") {
    console.log("Configuring for PROD environment");
    await Amplify.configure(awsConfiguration_prod);
}

function Provision() {
    const [uniqueId, setUniqueId] = useState('');
    const [bootstrapToken, setBootstrapToken] = useState('');      // result from start provisioning
    const [provisioningToken, setProvisioningToken] = useState('');
    const [showProvisionPanel, setShowProvisionPanel] = useState(false);
    const [showFakeMachinePanel, setShowFakeMachinePanel] = useState(false);
    const serialNumberRef = useRef(null);
    // provisioning certificate stuff
    // const [provisioningCertificatePem, setProvisioningCertificatePem] = useState(undefined);
    // const [provisioningPrivateKeyPem, setProvisioningPrivateKeyPem] = useState(undefined);
    // const [provisioningRootCAPem, setProvisioningRootCAPem] = useState(undefined);
    const provisioningCertificatePemRef = useRef(null);
    const provisioningPrivateKeyPemRef = useRef(null);
    const provisioningRootCAPemRef = useRef(null);

    const {fakeMachine} = useAppContext();

    useEffect( () => {
        if (fakeMachine != null) {
            setShowFakeMachinePanel(true);
            setShowProvisionPanel(false);
        } else {
            setShowFakeMachinePanel(false);
            setShowProvisionPanel(true);
        }

    }, [fakeMachine])

    // override services! TODO add password reset and let user specify preferred language somewhere
    const services = {
        async handleSignUp(input) {

            let { username, password, attributes } = input;
            // custom username and email
            username = username.toLowerCase();
            attributes = {
                "email": attributes.email.toLowerCase(),
                "custom:preferred_language": "it"
            }

            const params = {
                username,
                password,
                attributes,
                autoSignIn: {
                    enabled: true,
                },
            };
            console.log(params);

            return Auth.signUp(params);
        },
    };

    // <Authenticator services={{handleSignUp}} signUpAttributes={[]}>
    return (
        <Authenticator services={services}>
            <CognitoAuthContext>
                <CognitoUserInformation/>

                {showFakeMachinePanel &&
                    <Row className={"mt-1"}>
                        <Col>
                            <FakeMachineInfo fakeMachine={fakeMachine} />
                        </Col>
                    </Row>


                }

                {showProvisionPanel &&
                    <Row>
                        <Col>
                            <h4>ReST API</h4>
                            <StartProvisioningCaller
                                uniqueId={uniqueId}
                                setUniqueId={setUniqueId}
                                bootstrapToken={bootstrapToken}
                                setBootstrapToken={setBootstrapToken}
                                serialNumberRef={serialNumberRef}
                                provisioningCertificatePemRef={provisioningCertificatePemRef}
                                provisioningPrivateKeyPemRef={provisioningPrivateKeyPemRef}
                                provisioningRootCAPemRef={provisioningRootCAPemRef}
                            />

                            { provisioningToken &&
                                <CompleteProvisioningCaller
                                    uniqueId={uniqueId}
                                    provisioningToken={provisioningToken}
                                    serialNumberRef={serialNumberRef}
                                />
                            }
                        </Col>

                        <Col>
                            <h4>MQTT</h4>

                            { bootstrapToken &&
                                <IoTRequestProvisioning
                                    uniqueId={uniqueId} setUniqueId={setUniqueId}
                                    bootstrapToken={bootstrapToken}
                                    setProvisioningToken={setProvisioningToken}
                                    provisioningCertificatePemRef={provisioningCertificatePemRef}
                                    provisioningPrivateKeyPemRef={provisioningPrivateKeyPemRef}
                                    provisioningRootCAPemRef={provisioningRootCAPemRef}
                                />
                            }
                        </Col>
                    </Row>
                }
            </CognitoAuthContext>
        </Authenticator>

    );
}

//export default withAuthenticator(Provision);
export default Provision;
