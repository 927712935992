import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {completeProvisioning} from "../../../lib/backend/completeProvisioning";
import {saveFakeMachine} from "../../../lib/api/saveFakeMachine";
import {useState} from "react";
import {LoadingSpinner} from "../../ui/LoadingSpinner";
import {useAuth} from "../../auth/AuthProvider";
import {Auth} from "aws-amplify";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {CertificatesDownloader} from "./CertificatesDownloader";

export const CompleteProvisioningCaller = ({uniqueId, provisioningToken, serialNumberRef}) => {
    const [completeProvisioningError, setCompleteProvisioningError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [certificatePEM, setCertificatePEM] = useState(undefined);
    const [privateKeyPEM, setPrivateKeyPEM] = useState(undefined);
    const [rootCACertificatePEM, setRootCACertificatePEM] = useState(undefined);

    const {token} = useAuth();
    const { user} = useAuthenticator((context) => [context.user]);

    const handleCompleteProvisioning = (event) => {
        event.preventDefault();

        const unique_id = event.target.unique_id.value;
        const provisionig_token = event.target.provisioing_token.value;

        setCompleteProvisioningError(null);
        setLoading(true);

        completeProvisioning(unique_id, provisionig_token).then( (data) => {
            setCertificatePEM(data['certificate_pem']);
            setPrivateKeyPEM(data['private_key_pem']);
            setRootCACertificatePEM(data['root_ca_certificate_pem']);

        }).catch( (error) => {
            console.log(error);
            setCompleteProvisioningError(error['message']);
        }).finally( () => {
            setLoading(false);
        });
    }

    const handleSaveFakeMachine = (event) => {
        event.preventDefault();


        saveFakeMachine(
            token,
            user.username,
            user.attributes.email,
            uniqueId,
            serialNumberRef.current,
            certificatePEM,
            privateKeyPEM,
            rootCACertificatePEM
        ).then( res => {
            alert("Done!");
        }).catch( error => {
            console.error(error);
        })


    }


    const downloadCertificatePEM = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        //link.download = `${uniqueId}-certificate.pem`;
        link.download = `certificate.pem`;
        link.href = `data:plain/text;base64,${certificatePEM}`;
        link.click();
    };

    const downloadPrivateKeyPEM = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        //link.download = `${uniqueId}-private.key.pem`;
        link.download = `private.key.pem`;
        link.href = `data:plain/text;base64,${privateKeyPEM}`;
        link.click();
    };

    const downloadRootCACertificatePEM = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        // link.download = `${uniqueId}-root-ca.pem`;
        link.download = `root_ca.pem`;
        link.href = `data:plain/text;base64,${rootCACertificatePEM}`;
        link.click();
    };


    return (
        <div className={"functional-block"}>
            <Row className={"mt-2"}>
                <Col>
                    <Card>
                        <Container>
                            <Card.Title>Complete provisioning</Card.Title>
                            <Card.Body>
                                <Form onSubmit={handleCompleteProvisioning}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Unique ID</Form.Label>
                                            <Form.Control name="unique_id"
                                                          required
                                                          type="text"
                                                          value={uniqueId}
                                                          readOnly={true}
                                                          placeholder="Unique ID" />
                                        </Col>
                                        <Col>
                                            <Form.Label>Provisioning Token</Form.Label>
                                            <Form.Control name="provisioing_token"
                                                          required
                                                          readOnly={true}
                                                          type="text"
                                                          value={provisioningToken}
                                                          placeholder="Provisioning Token" />
                                        </Col>
                                    </Row>
                                    <Row className={"pt-3"}>
                                        <Col>
                                            <LoadingSpinner loadingFlag={loading}>
                                                <Button type="submit">Complete provisioning</Button>
                                            </LoadingSpinner>
                                        </Col>
                                    </Row>
                                </Form>

                            </Card.Body>
                        </Container>
                    </Card>
                </Col>
            </Row>

            <Row className={"mt-2"}>
                <Col>
                    <Card>
                        <Container>
                            <Card.Title>Result</Card.Title>
                            <Card.Body>
                                { certificatePEM && privateKeyPEM && rootCACertificatePEM &&
                                    <>
                                    <CertificatesDownloader
                                        certificatePEM={certificatePEM}
                                        privateKeyPEM={privateKeyPEM}
                                        rootCACertificatePEM={rootCACertificatePEM}
                                    />

                                    <p>
                                        <Button className={"btn-info"} onClick={handleSaveFakeMachine}>Save machine for as provisioned for user</Button>
                                    </p>
                                    </>
                                }
                                { completeProvisioningError &&
                                    <p className={"text-danger"}>{completeProvisioningError}</p>
                                }


                            </Card.Body>
                        </Container>
                    </Card>
                </Col>

            </Row>
        </div>
    )
};
