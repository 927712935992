import {reject_codes} from "./api_errors";

export default async function sendLocalRequest(path, method='POST', token = null, data= null, options = {}) {
    const headers = { ...(options.headers || {}),
        'Content-type': 'application/json; charset=UTF-8',
    };

    if (token) {
        headers['Authorization'] = token;
    }

    const promise = new Promise((resolve, reject) => {
        let opts = {
            method: method,
            // credentials: 'same-origin',
            ...options,
            headers,
        }

        if ( data != null ) {
            opts['body'] = JSON.stringify(data);
        }

        console.log(`send local request > 1 - before fetch (${path})`);
        fetch(`/api/${path}`, opts).then( (response) => {

            if (response.status === 404) {
                throw new Error(`API path "${path}" not found`);
            }

            // codified application errors
            if (response.status === 417) {
                // console.debug(response)
                response.json().then( json_data => {
                    reject(json_data['error_code'])
                })
            }

            else if (response.status !== 200) {
                response.text().then( text_data => {
                    console.log(text_data);
                    throw new Error("API Returned status code " + response.status + ", expected 200");
                })
            }

            else {
                response.json().then((json_data) => {
                    resolve(json_data);
                })
            }

        }).catch( error => {
            throw new Error("Error when contacting local API");
        })
    });

    return promise;

    /*
    const response = await fetch(`/api/${path}`, {
        method: method,
        // credentials: 'same-origin',
        body: JSON.stringify(data),
        ...options,
        headers,
    });

    if (response.status === 404) {
        throw new Error(`API path "${path}" not found`);
    }

    if (response.status === 401) {
        throw new ApiUnautherizedError("Unauthorized!");
    }

    const ret_data = await response.json();
    if (ret_data.error) {
        throw new Error(data.error);
    }

    return ret_data;
    */

}
