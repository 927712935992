import { Routes, Route} from "react-router-dom";

import LoginPage from './pages/LoginPage'
import Home from './pages/Home'
import Provision from "./pages/Provision";
import AppNavbar from "./components/AppNavbar";
import AppConfiguration from "./components/AppConfiguration";
import {AuthProvider} from "./components/auth/AuthProvider";
import {ProtectedRoute} from "./components/auth/ProtectedRoute";

import {Container} from "react-bootstrap";
import {AppContextProvider} from "./components/AppContext";
import {DebugPage} from "./pages/DebugPage";
import {OperateMachine} from "./pages/OperateMachine";

function App() {

    // const [token, setToken] = useState(null);

    // const confirmAuth = (_token) => {
    //     setToken(_token);
    // }

    return (
    <>
        <AppContextProvider>
            <AuthProvider>
                    <Routes>
                        <Route path={"/"} element={
                            <ProtectedRoute>
                                <AppNavbar/>
                                <Container className={"p-3"}>
                                    <Home />
                                </Container>
                            </ProtectedRoute>
                        } />

                        <Route path={"/provision"} element={
                            <ProtectedRoute>
                                <AppNavbar/>
                                <Container className={"p-3"}>
                                    <Provision />
                                </Container>
                            </ProtectedRoute>
                        } />

                        <Route path={"/operate-machine"} element={
                            <ProtectedRoute>
                                <AppNavbar/>
                                <Container className={"p-3"}>
                                    <OperateMachine />
                                </Container>
                            </ProtectedRoute>
                        } />

                        <Route path={"/login"} element={<LoginPage />} />

                        <Route path={"/debug"} element={
                            <Container className={"p-3"}>
                                <DebugPage />
                            </Container>
                        } />


                    </Routes>
            </AuthProvider>
        </AppContextProvider>
    </>
    );
}

export default App;
