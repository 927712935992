import {CertificatesDownloader} from "./CertificatesDownloader";
import {Button, Card} from "react-bootstrap";
import {useAuth} from "../../auth/AuthProvider";
import {deleteFakeMachine} from "../../../lib/api/deleteFakeMachine";
import {useNavigate} from "react-router-dom";

export const FakeMachineInfo = ( {fakeMachine}) => {

    const {token} = useAuth();
    const navigate = useNavigate();

    const handleDeleteFakeMachine = (event) => {
        event.preventDefault();
        deleteFakeMachine(token, fakeMachine.cognitoUsername).then( (res) => {
            window.location.reload(true);
        }).catch( (error) => {

        });
    }

    const operateMachine = (event) => {
        navigate("/operate-machine");
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Fake machine</Card.Title>
                <Card.Body>
                    <p>
                        <Button className={"btn-success"} onClick={operateMachine}>Operate fake machine</Button>
                    </p>
                    <p>
                        <Button className={"btn-info"} onClick={handleDeleteFakeMachine}>Delete fake machine</Button>
                    </p>
                    <CertificatesDownloader
                        certificatePEM={fakeMachine.mqttCert.certificatePEM}
                        privateKeyPEM={fakeMachine.mqttCert.privateKeyPEM}
                        rootCACertificatePEM={fakeMachine.mqttCert.rootCACertificatePEM}
                    />
                </Card.Body>

            </Card.Body>
        </Card>
    )
};
