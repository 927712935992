import {Card,Row, Col, Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useAppContext} from "../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import {useCognitoAuthContext} from "../../cognito/CognitoAuthContext";
import {getCurrentTsString} from "../../../helpers/time/getCurrentTsString";
import CopyToClipButton from "../../ui/CopyToClipButton";

export const MobileApplicationUpdatesReceiver = () => {

    const {fakeMachine} = useAppContext();
    const [lastNotificationTS, setLastNotificationTS] = useState();
    const [lastUpdateData, setLastUpdateData] = useState();

    const {jwt} = useCognitoAuthContext();

    useEffect( ()  => {
        if (!jwt || !fakeMachine) {
            // skip if not all the variables are ready
            return;

        }

        const subscriptionQuery = /* GraphQL */ `
              subscription MySubscription {
                  thingStatusUpdated (uniqueId: "${fakeMachine.machineUniqueID}") {
                    uniqueId dmk_0B00 dmk_0A05 dmk_0A04 dmk_0A03 dmk_0A02 dmk_0A01 dmk_0A00 dmk_050C dmk_050B dmk_050A dmk_0509 dmk_0508 dmk_0507 dmk_0506 dmk_0505 dmk_0504 dmk_0503 dmk_0502 dmk_0501 dmk_040A dmk_040B dmk_040C dmk_0409 dmk_0408 dmk_0407 dmk_0406 dmk_0405 dmk_0404 dmk_0403 dmk_0402 dmk_0401 dmk_030E dmk_030D dmk_030C dmk_030B dmk_030A dmk_0309 dmk_0308 dmk_0307 dmk_0306 dmk_0305 dmk_0304 dmk_0303 dmk_0302 dmk_0301 dmk_0201 dmk_0104 dmk_0103 dmk_0102 dmk_0101 dmk_0008 dmk_0007 dmk_0006 dmk_0005 dmk_0004 dmk_0003 dmk_0002 dmk_0001 
                  }
                }`;

        console.log("> subscribing to real time events receiver with the following query")
        console.log(subscriptionQuery);

        const extra_headers = {
            Authorization: `${jwt}`
        }

        const res = API.graphql(graphqlOperation(subscriptionQuery), extra_headers).subscribe({
            next: (evt) => {
                console.log("> Receiving real time updates")
                console.log(evt);

                console.log();
                setLastUpdateData(JSON.stringify(evt.value.data.thingStatusUpdated))
                setLastNotificationTS(getCurrentTsString());

                // const msg = evt.value.data.addedPost;
                // SubscriptionResult.innerHTML += `<h3>${msg.title} - ${msg.author}</h3><p>${msg.content}</p>`;
            },
        });




        console.log("subscription result");
        console.log(res);



    }, [jwt, fakeMachine]);

    return(
        <Row>
            <Col>
                <Card>
                    <Container>
                        <Card.Title>RT updates to mobile app</Card.Title>
                        <Card.Body>
                            { lastNotificationTS &&
                                <>
                                    <div className="status-update" >
                                        Last RT update: {lastNotificationTS}
                                    </div>
                                    <CopyToClipButton text={lastUpdateData}>Copy to clipboard last update data</CopyToClipButton>
                                </>
                            }
                        </Card.Body>
                    </Container>
                </Card>

            </Col>
        </Row>
    )
}
