import sendLocalRequest from "./sendLocalRequest";

export const callMqttProvisioningRequest = (auth_token, unique_id, bootstrap_token,
                                            provisioning_certificate_pem, provisioning_private_key_pem,
                                            provisioning_root_ca_pem) => {
    const data = {
        "uniqueID": unique_id,
        "bootstrapToken": bootstrap_token,
        "provisioningCertificatePem": provisioning_certificate_pem,
        "provisioningPrivateKeyPem": provisioning_private_key_pem,
        "provisioningRootCAPem": provisioning_root_ca_pem
    }
    console.log(">>>>>>>>> suca");
    console.log(data);

    return  new Promise( (resolve, reject) => {
        sendLocalRequest("call_mqtt_provisioning_request", "POST", auth_token, data).then( (res_data) => {
            resolve(res_data);
        }).catch( (error) => {
            reject(error);
        })
    });



};
