    import {createContext, useContext, useEffect, useRef, useState} from "react";
import {io} from "socket.io-client";
const AppContext = createContext();

export const AppContextProvider = ({children}) => {

    // const [socket, setSocket] = useState(null);

    // const socket = {
    //     disconnect: () => {},
    //     on: () => {}
    // };

    const [fakeMachine, setFakeMachine] = useState(undefined);

    const socket = io();

    useEffect( () => {
        // socket.on("connect", (data) => {
        //     console.log("webscket connected");
        // })

        return( () => {
            console.log("disconnecting socket");
            socket.disconnect();
        })
    }, []);

    const value = {
        socket: socket,
        fakeMachine: fakeMachine,
        setFakeMachine: setFakeMachine
    }



    return(
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
};

export const useAppContext = () => {
    return useContext(AppContext);
}
