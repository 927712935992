import { io } from 'socket.io-client';
import {Row, Col, Card, Container, Button, Form} from "react-bootstrap";
import {callMqttProvisioningRequest} from "../../../lib/api/callMqttProvisioningRequest";
import {useAuth} from "../../auth/AuthProvider";
import {useEffect, useState, useRef} from "react";
import {useAppContext} from "../../AppContext";
import {LoadingSpinner} from "../../ui/LoadingSpinner";

// let socket;

export const IoTRequestProvisioning = ({uniqueId, setUniqueId, bootstrapToken,
                                           setProvisioningToken,  provisioningCertificatePemRef,
                                           provisioningPrivateKeyPemRef, provisioningRootCAPemRef}) => {
    const [loading, setLoading] = useState(false);
    const loadingRef = useRef(loading);
    const [websocketTimeout, setWebsocketTimeout] = useState(false);
    const uniqueIdRef = useRef(uniqueId);

    const {token} = useAuth();

    const {socket} = useAppContext();

    useEffect( () => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect( () => {
        uniqueIdRef.current = uniqueId;
    }, [uniqueId]);

    useEffect( () => {

        if (!socket) {
            return;
        }
        socket.connect();

        socket.on("connect", (data) => {
            console.log("connected");
        });

        socket.on("provisioning_response", (data) => {
            console.log("provisioning response received");

            if (data["unique_id"] === uniqueIdRef.current) {
                console.log(">>> websocket data received >>");

                setLoading(false);
                setWebsocketTimeout(false);
                // the message is for my device
                const provisioning_token = data['msg']['provisioning_token'];
                setProvisioningToken(provisioning_token);
            }

        })

        return( () => {
            console.log("disconnecting socket");
            socket.disconnect();
        })

    }, [socket]);


    const handleRequestProvisioningToken = event => {
        event.preventDefault();
        setWebsocketTimeout(false);
        setLoading(true);

        console.log(">> 1");
        setTimeout(() => {
            if (loadingRef.current) {
                setWebsocketTimeout(true);
                setLoading(false);
            }
        }, 60000);
        console.log(">> 2");

        callMqttProvisioningRequest(token, uniqueId, bootstrapToken, provisioningCertificatePemRef.current,
                provisioningPrivateKeyPemRef.current, provisioningRootCAPemRef.current).then(res_data => {
            console.log("call mqtt provisioning request result");
            console.log(res_data);
            console.log("<<");
        }). catch(error => {
            console.error(error);
        })
    }

    return (
        <div className={"functional-block"}>
            <Row className={"mt-2"}>
                <Col>
                    <Card>
                        <Container >
                            <Card.Title>Request provisioning token</Card.Title>

                            <Card.Body>
                                <Form onSubmit={handleRequestProvisioningToken}>
                                    <Row>
                                        <Col>
                                            <Form.Label>Unique ID</Form.Label>
                                            <Form.Control name="unique_id"
                                                          required
                                                          type="text"
                                                          value={uniqueId}
                                                          onChange={e => setUniqueId(e.target.value)}
                                                          placeholder="Unique ID" />
                                        </Col>
                                        <Col>
                                            <Form.Label>Bootstrap token</Form.Label>
                                            <Form.Control name="bootstrap_token"
                                                          required
                                                          readOnly={true}
                                                          type="text"
                                                          value={bootstrapToken}
                                                          placeholder="Bootstrap token" />
                                        </Col>
                                    </Row>
                                    <Row className={"pt-3"}>
                                        { websocketTimeout && <div className="alert alert-danger" role="alert">
                                            Timeout when contacting MQTT
                                        </div> }
                                        <LoadingSpinner loadingFlag={loading}>
                                            <Button type="submit">Request</Button>
                                        </LoadingSpinner>

                                    </Row>
                                </Form>
                            </Card.Body>
                        </Container>
                    </Card>

                </Col>
            </Row>
        </div>
    )
}
