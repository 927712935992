import sendLocalRequest from "./sendLocalRequest";

export const callMqttUpdateStatus = (auth_token, unique_id, update_payload) => {
    const data = {
        "uniqueID": unique_id,
        "payload": update_payload
    }

    return  new Promise( (resolve, reject) => {
        sendLocalRequest("call_mqtt_update_status", "POST", auth_token, data).then( (res_data) => {
            resolve(res_data);
        }).catch( (error) => {
            reject(error);
        })
    });



};
