import {useAuth} from "./AuthProvider";
import {Navigate, useNavigate} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import {useEffect} from "react";

export const ProtectedRoute = ({children}) => {
    const {checkUserLogged} = useAuth();

    const navigate= useNavigate();

    useEffect( () => {
        checkUserLogged().then( is_logged => {
            if (!is_logged) {
                //return <Navigate to={"/login"} replace />
                navigate("/login");
            }
        })
    }, [navigate])


    return children;
}
