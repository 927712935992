import {useState, createContext, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {verifyToken} from "../../lib/api/verifyToken";
import {renewToken} from "../../lib/api/renewToken";
import {reject_codes} from "../../lib/api/api_errors";
import AppConfiguration from "../AppConfiguration";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate();



    const checkUserLogged = () => {
        const _token = _getStoredToken();
        // set token state in order to pass down to the components via the provider
        setToken(_token);

        return new Promise( (resolve) => {
            if (!_token) {
                resolve(false);
                return;
            }

            verifyToken(_token).then( data => {
                resolve(true);

                // if (data.valid === true) {
                //     resolve(true);
                // } else {
                //     _removeToken();
                //     resolve(false);
                // }
            }).catch( error => {
                if (error === reject_codes.UNAUTHORIZED) {
                    _removeToken();
                    resolve(false);
                    return;
                }
                else if (error === reject_codes.TOKEN_EXPIRED) {
                    const _refreshToken = _getStoredRefreshToken()

                    renewToken(_token, _refreshToken).then( data => {
                        // load new token
                        _removeToken()

                        _saveToken(data.token, data.refresh_token);
                        navigate("/");
                        return;


                    }).catch( error => {
                        // unable to refresh, delete the token and send to login pag
                        _removeToken();
                        resolve(false);
                        return;
                    })

                    return;

                }
                console.error(error);
                throw new Error(error);
            })
        })

    }

    const _getStoredToken = () => {
        return localStorage.getItem("dld_auth_token")
    }

    const _getStoredRefreshToken = () => {
        return localStorage.getItem("dld_refresh_token")
    }

    const _saveToken = (_token, _refreshToken) => {
        localStorage.setItem("dld_auth_token", _token);
        localStorage.setItem("dld_refresh_token", _refreshToken);
        setToken(token);
    }

    const _removeToken = () => {
        localStorage.removeItem("dld_auth_token");
        localStorage.removeItem("dld_refresh_token");
        setToken(null);
    }

    const handleLogin = async (token, refreshToken, env, apiEndpoint) => {
        // setToken(token);
        _saveToken(token, refreshToken);
        AppConfiguration.setApiInformation(env, apiEndpoint)
        navigate("/")
    }

    const handleLogout = () => {
        _removeToken();
        AppConfiguration.clean();
    }


    const getDeviceId = () => {
        let deviceId = localStorage.getItem("dld_device_id");
        if (deviceId == null) {
            deviceId = crypto.randomUUID();
            localStorage.setItem("dld_device_id", deviceId);
        }
        return deviceId;
    }

    const value = {
        token: token,
        getDeviceId: getDeviceId,
        checkUserLogged: checkUserLogged,
        doLogin: handleLogin,
        doLogout: handleLogout
    }

    return(
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => {
    return useContext(AuthContext);
};
