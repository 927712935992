import {useAuth} from "../components/auth/AuthProvider";
import {Button} from "react-bootstrap";

export const DebugPage = () => {
    const {doLogout} = useAuth();

    const _logout = () => {
        doLogout();
        alert("done");
    }

    return(
        <>
            <Button onClick={_logout}>Logout</Button>
        </>

    )
};


