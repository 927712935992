import sendLocalRequest from "./sendLocalRequest";

export const getFakeMachine = (auth_token, cognito_username) => {
    return  new Promise( (resolve, reject) => {
        sendLocalRequest(`fake_machine/${cognito_username}`, "GET", auth_token).then( (data) => {
            resolve(data);
        }).catch( (error) => {
            reject(error);
        })
    });



};
