class ConfigurationClass {
    clean() {
        localStorage.removeItem("dld_env");
        localStorage.removeItem("dld_apiEndpoint");
    }

    setApiInformation(env, apiEndpoint) {
        localStorage.setItem("dld_env", env);
        localStorage.setItem("dld_apiEndpoint", apiEndpoint);
    };

    getEnv() {
        return localStorage.getItem("dld_env");
    }

    getApiEndpoint() {
        return localStorage.getItem("dld_apiEndpoint");
    }


}

const AppConfiguration = new ConfigurationClass();
export default AppConfiguration;
