import 'isomorphic-unfetch';
import getRootUrl from './getRootUrl';
import {Auth} from "aws-amplify";

export default async function sendRequest(path, method='POST', auth=true, data=null, options = {}) {
    const headers = { ...(options.headers || {}),
        'Content-type': 'application/json; charset=UTF-8',
        // 'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
    };

    if (auth) {
        headers['Authorization'] = (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    return new Promise( (resolve, reject) => {
        let args = {
            method: method,
            ...options,
            headers
        };
        if (data) {
            args["body"] = JSON.stringify(data);
        }


        fetch(`${getRootUrl()}${path}`, args)
        .then( (response) => {
        if (response.status === 200) {
            response.json().then( json_data => {
                resolve(json_data);
            })
        } else {
            response.json().then( json_data => {
                reject(json_data);
            })
        }
        })
        .catch( (error)  => {
            console.error(error);
            reject(error);
        })

    } );

}
