import { Col, Button, Row, Container, Card, Form, Alert } from "react-bootstrap";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {loginApi} from "../lib/api/LoginApi";
import {useAuth} from "../components/auth/AuthProvider";
import {reject_codes} from "../lib/api/api_errors";
import {LoadingSpinner} from "../components/ui/LoadingSpinner";

export default function LoginPage() {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const navigate = useNavigate();
    const {doLogin, getDeviceId} = useAuth();


    const handleSubmit = async (event) => {
        event.preventDefault();

        const username = event.target.username.value;
        const password = event.target.password.value;
        const deviceId = getDeviceId();

        setLoading(true);
        loginApi(username, password, deviceId).then( ([_token, _refreshToken, _env, _apiEndpoint]) => {
            doLogin(_token, _refreshToken, _env, _apiEndpoint);
            navigate("/");
        }).catch((reject_code) => {
            if (reject_code === reject_codes.UNAUTHORIZED) {
                setAlertMessage("Wrong credentials");
                setShowAlert(true);
            } else {
                throw Error("General error");
            }
        }). finally( () => {
            setLoading(false);
        })
    };


    return (
        <div>
            <Container>

                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    { showAlert && <Alert variant="danger"
                                          onClose={() => {setShowAlert(false); setAlertMessage("")}} dismissible>
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>
                            {alertMessage}
                        </p>
                    </Alert>}

                    <Col md={8} lg={6} xs={12}>
                        <div className="border border-3 border-primary"></div>
                        <Card className="shadow">
                            <Card.Body>
                                <div className="mb-3 mt-md-4">
                                    <h2 className="fw-bold mb-2 text-uppercase ">LANG-Dashboard</h2>
                                    <div className="mb-3">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label className="text-center">
                                                    Username
                                                </Form.Label>
                                                <Form.Control name="username" type="text" placeholder="Enter username"/>
                                            </Form.Group>

                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicPassword"
                                            >
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control name="password" type="password" placeholder="Password" />
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicCheckbox"
                                            >
                                            </Form.Group>
                                            <div className="d-grid">
                                                <LoadingSpinner loadingFlag={loading}>
                                                    <Button variant="primary" type="submit">
                                                        Login
                                                    </Button>
                                                </LoadingSpinner>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
