import {Navbar, Nav, Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
// import {AuthContext} from "./App";
import {useAuth} from "./auth/AuthProvider"
import {LoadingSpinner} from "./ui/LoadingSpinner";

export default function AppNavbar() {
    const {doLogout} = useAuth();
    const navigate = useNavigate();
    // const token = getValidToken();

    const logout = () => {
        doLogout();
        navigate("/login");
    }

    return (
        <Navbar className={"bg-dark-subtle"}>
            <Nav className={"p-1"}>
                <Nav.Link href="/">Home</Nav.Link>
            </Nav>
            <Nav className={"p-1"}>
                <Nav.Link href="/provision">Provision</Nav.Link>
            </Nav>

            <Nav className={"ms-auto p-1"}>
                <Button onClick={logout}>Logout</Button>
            </Nav>
        </Navbar>

    )
}
