import {Button, Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import Select, {components} from "react-select";
import {startProvisioning} from "../../../lib/backend/startProvisioning";
import {useState, useEffect} from "react";
import {useAuth} from "../../auth/AuthProvider";
import {getSerialNumbers} from "../../../lib/api/getSerialNumbers";
import {LoadingSpinner} from "../../ui/LoadingSpinner";


const SerialNumberCustomOption = props => {
    return (
        <components.Option {...props} >
            {props.data.label}<br/>
            <small style={{color: 'gray'}}>
                {props.data.attachedTo || 'free'}
            </small>
        </components.Option>
    )};


export const StartProvisioningCaller = ({uniqueId, setUniqueId,
                                            bootstrapToken, setBootstrapToken,
                                            serialNumberRef, provisioningCertificatePemRef,
                                            provisioningRootCAPemRef, provisioningPrivateKeyPemRef
                                        }) => {
    const [loading, setLoading] = useState(false);
    const [startProvisioningError, setStartProvisioningError] = useState(null);

    const [bootstrapTokenExpire, setBootstrapTokenExpire] = useState(null);
    const [haveStartProvisioningResult, setHaveStartProvisioningResult] = useState(false);
    const [serialNumberOptions, setSerialNumberOptions] = useState([]);
    const [selectedSerialNumberOption, setSelectedSerialNumberOption] = useState(null);

    const {token} = useAuth();

    useEffect( () => {
        if (token != null) {
            getSerialNumbers(token).then(data => {
                let opts = [];
                data.forEach( item => {
                    opts.push( {
                        value: item['serialNumber'],
                        label: item['serialNumber'],
                        attachedTo: item['attachedTo']
                    } )
                });
                setSerialNumberOptions(opts);

            }).catch(error => {
                console.log(error);
                throw new Error("Error when getting serial numbers from dynamodb");
            });

        }
    }, [token]);

    const onSerialNumberChange = (cur_val) => {
        try {
            if (cur_val.attachedTo != undefined) {
                setUniqueId(cur_val.attachedTo);
            } else {
                if (uniqueId != undefined) {
                    setUniqueId("");
                }
            }
        } catch (e) {}
        setSelectedSerialNumberOption(cur_val);


    }


    function handleStartProvisioning(event) {
        event.preventDefault();
        setLoading(true);

        const serial_number = selectedSerialNumberOption.value;

        serialNumberRef.current = serial_number;

        setStartProvisioningError(null);

        startProvisioning(uniqueId, serial_number).then( (data) => {
            setBootstrapToken(data['bootstrap_token']);
            setBootstrapTokenExpire(data['expire_ts']);
            // setCertificatePem(data['certificate_pem']);
            // setPrivateKeyPem(data["private_key_pem"]);
            // setRootCAPem(data["root_ca_certificate_pem"]);

            provisioningCertificatePemRef.current = data['certificate_pem'];
            provisioningPrivateKeyPemRef.current = data["private_key_pem"];
            provisioningRootCAPemRef.current = data["root_ca_certificate_pem"];


            setHaveStartProvisioningResult(true);
        }).catch( (error) => {
            console.log("Error when calling start provisioning");
            console.log(error);
            let message = error["msg"];
            if (!message) {
                message = error["message"];
            }
            setStartProvisioningError(message);
        }).finally( () => {
            setLoading(false);
        });
    }






    return (
        <div className={"functional-block"}>
        <Row>
            <Col>
                <Card>
                    <Container>
                        <Card.Title>Start provisioning</Card.Title>
                        <Card.Body>
                            <Form onSubmit={handleStartProvisioning}>
                                <Row>
                                    <Col>
                                        <Form.Label>Unique ID</Form.Label>
                                        <Form.Control name="unique_id"
                                                      required
                                                      type="text"
                                                      value={uniqueId}
                                                      onChange={e => setUniqueId(e.target.value)}
                                                      placeholder="Unique ID" />
                                    </Col>
                                    <Col>
                                        <Form.Label>Serial Number</Form.Label>

                                        <Select
                                            defaultValue={selectedSerialNumberOption}
                                            onChange={onSerialNumberChange}
                                            options={serialNumberOptions}
                                            components={{Option: SerialNumberCustomOption}}
                                            required

                                        />
                                    </Col>
                                </Row>
                                <Row className={"pt-3"}>
                                        <LoadingSpinner loadingFlag={loading}>
                                            <Button type="submit">
                                                Start Provisioning
                                            </Button>
                                        </LoadingSpinner>

                                </Row>
                            </Form>

                        </Card.Body>
                    </Container>
                </Card>
            </Col>
        </Row>

        <Row className={"mt-2"}>
            <Col>
                <Card>
                    <Container>
                        <Card.Title>Result</Card.Title>
                        <Card.Body>
                            { haveStartProvisioningResult &&
                                <>
                                    <p>Bootstrap token: {bootstrapToken}</p>
                                    <p>Expire: {bootstrapTokenExpire}</p>
                                </>
                            }
                            { startProvisioningError &&
                                <p className={"text-danger"}>{startProvisioningError}</p>
                            }


                        </Card.Body>
                    </Container>
                </Card>
            </Col>

        </Row>
        </div>
    )
};
